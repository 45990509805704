import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';

@Injectable({
    providedIn: 'root'
})
export class FacturafullRedirectGuard implements CanActivate {
    canActivate(): boolean {
        window.location.href = 'http://facilcontabilidad.org/facturafull';
        return false;
    }
}
