export class TodoFakeData {
  public static tasks = [
    {
      id: 1,
      title: 'Entire change break our wife wide it daughter mention member.',
      dueDate: '2020-11-25',
      description:
        '<p>Chocolate cake topping bonbon jujubes donut sweet wafer. Marzipan gingerbread powder brownie bear claw. Chocolate bonbon sesame snaps jelly caramels oat cake.</p>',
      assignee: {
        fullName: 'Jacob Ramirez',
        avatar: 'assets/images/avatars/12.png'
      },
      tags: ['update'],
      completed: false,
      deleted: false,
      important: false
    },
    {
      id: 2,
      title: 'Citizen stand administration step agency century.',
      dueDate: '2020-12-14',
      description:
        '<p>Chocolate cake topping bonbon jujubes donut sweet wafer. Marzipan gingerbread powder brownie bear claw. Chocolate bonbon sesame snaps jelly caramels oat cake.</p>',
      assignee: {
        fullName: 'Andrew Anderson',
        avatar: ''
      },
      tags: ['team', 'medium'],
      completed: true,
      deleted: false,
      important: false
    },
    {
      id: 3,
      title: 'Meet Jane and ask for coffee ❤️',
      dueDate: '2020-11-25',
      description:
        '<p>Chocolate cake topping bonbon jujubes donut sweet wafer. Marzipan gingerbread powder brownie bear claw. Chocolate bonbon sesame snaps jelly caramels oat cake.</p>',
      assignee: {
        fullName: 'Benjamin Jacobs',
        avatar: ''
      },
      tags: ['high'],
      completed: false,
      deleted: false,
      important: false
    },
    {
      id: 4,
      title: 'Answer the support tickets and close completed tickets. ',
      dueDate: '2020-11-20',
      description:
        '<p>Chocolate cake topping bonbon jujubes donut sweet wafer. Marzipan gingerbread powder brownie bear claw. Chocolate bonbon sesame snaps jelly caramels oat cake.</p>',
      assignee: {
        fullName: 'Curtis Schmidt',
        avatar: 'assets/images/avatars/9.png'
      },
      tags: ['medium'],
      completed: false,
      deleted: false,
      important: true
    },
    {
      id: 5,
      title: 'Test functionality of apps developed by dev team for enhancements. ',
      dueDate: '2020-12-06',
      description:
        '<p>Chocolate cake topping bonbon jujubes donut sweet wafer. Marzipan gingerbread powder brownie bear claw. Chocolate bonbon sesame snaps jelly caramels oat cake.</p>',
      assignee: {
        fullName: 'Katherine Perkins',
        avatar: 'assets/images/avatars/9.png'
      },
      tags: ['medium'],
      completed: true,
      deleted: false,
      important: true
    },
    {
      id: 6,
      title: 'Conduct a mini awareness meeting regarding health care. ',
      dueDate: '2020-12-06',
      description:
        '<p>Chocolate cake topping bonbon jujubes donut sweet wafer. Marzipan gingerbread powder brownie bear claw. Chocolate bonbon sesame snaps jelly caramels oat cake.</p>',
      assignee: {
        fullName: 'David Murphy',
        avatar: ''
      },
      tags: ['high', 'medium'],
      completed: true,
      deleted: true,
      important: false
    },
    {
      id: 7,
      title: 'Plan new dashboardadmin design with design team for Google app store. ',
      dueDate: '2020-12-05',
      description:
        '<p>Chocolate cake topping bonbon jujubes donut sweet wafer. Marzipan gingerbread powder brownie bear claw. Chocolate bonbon sesame snaps jelly caramels oat cake.</p>',
      assignee: {
        fullName: 'Karina Miller',
        avatar: 'assets/images/avatars/1.png'
      },
      tags: ['medium'],
      completed: false,
      deleted: false,
      important: true
    },
    {
      id: 8,
      title: 'Pick up Nats from her school and drop at dance class😁 ',
      dueDate: '2020-12-08',
      description:
        '<p>Chocolate cake topping bonbon jujubes donut sweet wafer. Marzipan gingerbread powder brownie bear claw. Chocolate bonbon sesame snaps jelly caramels oat cake.</p>',
      assignee: {
        fullName: 'Thomas Moses',
        avatar: 'assets/images/avatars/7.png'
      },
      tags: ['low', 'medium'],
      completed: false,
      deleted: false,
      important: false
    },
    {
      id: 9,
      title: 'Finish documentation and make it live',
      dueDate: '2020-11-25',
      description:
        '<p>Chocolate cake topping bonbon jujubes donut sweet wafer. Marzipan gingerbread powder brownie bear claw. Chocolate bonbon sesame snaps jelly caramels oat cake.</p>',
      assignee: {
        fullName: 'Angel Morrow',
        avatar: ''
      },
      tags: ['high', 'update'],
      completed: false,
      deleted: true,
      important: false
    },
    {
      id: 10,
      title: 'List out all the SEO resources and send it to new SEO team. ',
      dueDate: '2020-12-09',
      description:
        '<p>Chocolate cake topping bonbon jujubes donut sweet wafer. Marzipan gingerbread powder brownie bear claw. Chocolate bonbon sesame snaps jelly caramels oat cake.</p>',
      assignee: {
        fullName: 'Karen Carrillo',
        avatar: ''
      },
      tags: ['low'],
      completed: true,
      deleted: false,
      important: false
    },
    {
      id: 11,
      title: 'Refactor Code and fix the bugs and test it on server ',
      dueDate: '2020-12-01',
      description:
        '<p>Chocolate cake topping bonbon jujubes donut sweet wafer. Marzipan gingerbread powder brownie bear claw. Chocolate bonbon sesame snaps jelly caramels oat cake.</p>',
      assignee: {
        fullName: 'Timothy Brewer',
        avatar: 'assets/images/avatars/1.png'
      },
      tags: ['low'],
      completed: true,
      deleted: false,
      important: true
    },
    {
      id: 12,
      title: 'Reminder to mail clients for holidays',
      dueDate: '2020-12-09',
      description:
        '<p>Chocolate cake topping bonbon jujubes donut sweet wafer. Marzipan gingerbread powder brownie bear claw. Chocolate bonbon sesame snaps jelly caramels oat cake.</p>',
      assignee: {
        fullName: 'Brian Barry',
        avatar: ''
      },
      tags: ['team'],
      completed: false,
      deleted: false,
      important: false
    },
    {
      id: 13,
      title: "Submit quotation for Abid's ecommerceadmin website and admin project",
      dueDate: '2020-12-01',
      description:
        '<p>Chocolate cake topping bonbon jujubes donut sweet wafer. Marzipan gingerbread powder brownie bear claw. Chocolate bonbon sesame snaps jelly caramels oat cake.</p>',
      assignee: {
        fullName: 'Joshua Golden',
        avatar: 'assets/images/avatars/5.png'
      },
      tags: ['team'],
      completed: false,
      deleted: false,
      important: false
    },
    {
      id: 14,
      title: 'Send PPT with real-time reports',
      dueDate: '2020-11-29',
      description:
        '<p>Chocolate cake topping bonbon jujubes donut sweet wafer. Marzipan gingerbread powder brownie bear claw. Chocolate bonbon sesame snaps jelly caramels oat cake.</p>',
      assignee: {
        fullName: 'Paula Hammond',
        avatar: 'assets/images/avatars/5.png'
      },
      tags: ['medium'],
      completed: true,
      deleted: false,
      important: true
    },
    {
      id: 15,
      title: 'Skype Tommy for project status & report',
      dueDate: '2020-11-29',
      description:
        '<p>Chocolate cake topping bonbon jujubes donut sweet wafer. Marzipan gingerbread powder brownie bear claw. Chocolate bonbon sesame snaps jelly caramels oat cake.</p>',
      assignee: {
        fullName: 'Tyler Garcia',
        avatar: ''
      },
      tags: ['medium'],
      completed: false,
      deleted: false,
      important: false
    },
    {
      id: 16,
      title: 'Hire 5 new Fresher or Experienced, frontend and backend developers ',
      dueDate: '2020-12-12',
      description:
        '<p>Chocolate cake topping bonbon jujubes donut sweet wafer. Marzipan gingerbread powder brownie bear claw. Chocolate bonbon sesame snaps jelly caramels oat cake.</p>',
      assignee: {
        fullName: 'Darlene Shields',
        avatar: 'assets/images/avatars/1.png'
      },
      tags: ['low'],
      completed: true,
      deleted: false,
      important: false
    },
    {
      id: 17,
      title: 'Plan a party for development team 🎁',
      dueDate: '2020-12-04',
      description:
        '<p>Chocolate cake topping bonbon jujubes donut sweet wafer. Marzipan gingerbread powder brownie bear claw. Chocolate bonbon sesame snaps jelly caramels oat cake.</p>',
      assignee: {
        fullName: 'Destiny Michael',
        avatar: ''
      },
      tags: ['medium', 'low'],
      completed: false,
      deleted: false,
      important: false
    },
    {
      id: 18,
      title: 'Fix Responsiveness for new structure 💻',
      dueDate: '2020-11-18',
      description:
        '<p>Chocolate cake topping bonbon jujubes donut sweet wafer. Marzipan gingerbread powder brownie bear claw. Chocolate bonbon sesame snaps jelly caramels oat cake.</p>',
      assignee: {
        fullName: 'Danielle Anderson',
        avatar: 'assets/images/avatars/12.png'
      },
      tags: ['low'],
      completed: false,
      deleted: false,
      important: true
    }
  ];

  public static assignee = [
    { fullName: 'Jacob Ramirez', avatar: 'assets/images/portrait/small/avatar-s-3.jpg' },
    { fullName: 'Andrew Anderson', avatar: 'assets/images/portrait/small/avatar-s-1.jpg' },
    { fullName: 'Benjamin Jacobs', avatar: 'assets/images/portrait/small/avatar-s-4.jpg' },
    { fullName: 'Curtis Schmidt', avatar: 'assets/images/portrait/small/avatar-s-6.jpg' },
    { fullName: 'Katherine Perkins', avatar: 'assets/images/portrait/small/avatar-s-2.jpg' },
    { fullName: 'David Murphy', avatar: 'assets/images/portrait/small/avatar-s-11.jpg' },
    { fullName: 'Karina Miller', avatar: 'assets/images/portrait/small/avatar-s-2.jpg' },
    { fullName: 'Thomas Moses', avatar: 'assets/images/portrait/small/avatar-s-1.jpg' },
    { fullName: 'Angel Morrow', avatar: 'assets/images/portrait/small/avatar-s-6.jpg' },
    { fullName: 'Karen Carrillo', avatar: 'assets/images/portrait/small/avatar-s-3.jpg' },
    { fullName: 'Timothy Brewer', avatar: 'assets/images/portrait/small/avatar-s-11.jpg' },
    { fullName: 'Brian Barry', avatar: 'assets/images/portrait/small/avatar-s-4.jpg' },
    { fullName: 'Joshua Golden', avatar: 'assets/images/portrait/small/avatar-s-11.jpg' },
    { fullName: 'Paula Hammond', avatar: 'assets/images/portrait/small/avatar-s-3.jpg' },
    { fullName: 'Tyler Garcia', avatar: 'assets/images/portrait/small/avatar-s-11.jpg' },
    { fullName: 'Darlene Shields', avatar: 'assets/images/portrait/small/avatar-s-4.jpg' },
    { fullName: 'Destiny Michael', avatar: 'assets/images/portrait/small/avatar-s-11.jpg' },
    { fullName: 'Danielle Anderson', avatar: 'assets/images/portrait/small/avatar-s-2.jpg' }
  ];

  public static filters = [
    {
      id: 0,
      handle: 'all',
      title: 'My Task',
      icon: 'mail'
    },
    {
      id: 1,
      handle: 'important',
      title: 'Important',
      icon: 'star'
    },
    {
      id: 2,
      handle: 'completed',
      title: 'Completed',
      icon: 'check'
    },
    {
      id: 3,
      handle: 'deleted',
      title: 'Deleted',
      icon: 'trash'
    }
  ];

  public static tags = [
    {
      id: 0,
      handle: 'team',
      title: 'Team',
      color: 'bullet-primary'
    },
    {
      id: 1,
      handle: 'low',
      title: 'Low',
      color: 'bullet-success'
    },
    {
      id: 2,
      handle: 'medium',
      title: 'Medium',
      color: 'bullet-warning'
    },
    {
      id: 3,
      handle: 'high',
      title: 'High',
      color: 'bullet-danger'
    },
    {
      id: 3,
      handle: 'update',
      title: 'Update',
      color: 'bullet-info'
    }
  ];
}
