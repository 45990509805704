import { Role } from './role';


export class User {
  firstName: string;
  lastName: string;
  id: number;
  username: string;
  email: string;
  password: string;
  name: string;
  emailVerificated: number;
  detalles: object;
  direccion: string;
  direccionformateada: string;
  documentos: number;
  celular: string;
  ruc: string;
  ciudad: string;
  provincia: string;
  roleId: Role;
  role: Role;
  plan: string;
  cardtoken: string;
  datacard: string;
  storage: number;
  storageff: number;
  storagedc: number;
  storageif: number;
  metodo: string;
  ipregister: string;
  lastip: string;
  version: string;
  latitud: string;
  longitud: string;
  new_password_key: string;
  new_password_request: string;
  isBanned: number;
  banReason: string;
  aplicaciones: string;
  isActive: number;
  isActiveFF: number;
  isActiveDC: number;
  isActiveIF: number;
  modopago: string;
  modopagocx: string;
  modopagodc: string;
  modopagoif: string;
  valorpago: string;
  periodopago: number;
  fechapago: string;
  valorpagocx: number;
  periodopagocx: number;
  fechapagocx: string;
  valorpagodc: number;
  periodopagodc: number;
  fechapagodc: string;
  valorpagoif: number;
  periodopagoif: number;
  fechapagoif: string;
  tokenpush: string;
  codigopush: number;
  pop3_user: string;
  pop3_password: string;
  pop3_server: string;
  smtp_do_authorization: string;
  smtp_user: string;
  smtp_password: string;
  smtp_server: string;
  email_from_address: string;
  email_from_name: string;
  createdAt: string;
  updatedAt: string;
  fullName: string;
  company: string;
  country: string;
  contact: string;
  currentPlan: string;
  status: string;
  avatar: string;
  usuarioFirebase: string;
  dataFirebase: string;
  token: string;
  avatarUrl: string;
}
