import { HttpClient, HttpEvent, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { ServiceBase } from '../ServiceBase';

@Injectable({
  providedIn: 'root'
})
export class FileService {

  urlApi = environment.apiUrl;
  httpClient: any;
  fileLocal$ = new ReplaySubject(1);
  constructor(
    private http: HttpClient,
    private serviceBase: ServiceBase
    ) { }

  getFileList( filter?: any ) {
    let url = this.urlApi + '/archivos';
    url = this.serviceBase.crearUrlParametros(url, filter);
    return this.http.get(`${url}`);
  }

  uploadFile( formData: any ): Observable<HttpEvent<any>> {
    const url = this.urlApi + '/upload';
    const req = new HttpRequest('POST', `${url}`, formData, {
      reportProgress: true,
      responseType: 'json'
    });

    return this.http.request(req);
  }

  createFile( body: any ): Observable<HttpEvent<any>> {
    const url = this.urlApi + '/archivos';
    const req = new HttpRequest('POST', `${url}`, body);

    return this.http.request(req);
  }

  getOptionSearchFileList( body: string[] ): Observable<HttpEvent<any>> {
    let url = this.urlApi + '/buscador';
    url = this.serviceBase.crearUrlParametros(url, body);
    const req = new HttpRequest('GET', `${url}`, {
      reportProgress: true,
      responseType: 'json'
    });
    return this.http.request(req);
  }

  searchFileListLocal(value: string, fileList: any[]): Observable<any>{
    let fileListResult = [];
    try {
      if(fileList != undefined){
        const valueSearch = value.toUpperCase();
        if(fileList.length > 0){
          //Busco conincidencias por nombre del archivo
          let fileListSearch: any[] = fileList.filter(file => file.nombre.toUpperCase().includes(valueSearch));
          if(fileListSearch.length > 0){
            fileListSearch.forEach(fileSearch => {
              fileListResult.push(fileSearch);
            });
            this.fileLocal$.next(fileListResult);
          }
          //Extraigo los array de equitas existentes
          const fileListWithEquitas: any[] = fileList.filter(fileEtiqueta => fileEtiqueta.etiqueta != '' && fileEtiqueta.etiqueta != null && fileEtiqueta.etiqueta != undefined);
          if(fileListWithEquitas.length > 0){
            fileListWithEquitas.forEach(fileWithEtiquetas => {
              const etiquetok: any[] = fileWithEtiquetas.etiqueta.filter(fileEtiqueta => fileEtiqueta.nombre.toUpperCase().includes(valueSearch) || fileEtiqueta.valor.toUpperCase().includes(valueSearch));
              if(etiquetok.length > 0){
                fileListResult.push(fileWithEtiquetas);
              }
            });
            this.fileLocal$.next(fileListResult);
          }
        }
      }
    } catch (error) {
      this.fileLocal$.next(fileListResult);
      console.error('Error FileService -> searchFileListLocal', error);
    }
    if(fileListResult.length === 0){
      this.fileLocal$.next([]);
    }
    this.fileLocal$.complete();
    return this.fileLocal$;
  }

}
