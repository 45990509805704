// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  hmr: false,
  // apiUrl: 'http://localhost:8080',
  // apiUrl: 'https://186.4.170.239',
  apiUrl: 'https://facilcontabilidad.store',
  entityIdDatafast: '8ac7a4c981151a7a0181166a8b39026b',
  entityIdDatafastProd: '8acda4cb81ae83220181bc182d9749d9',
  accessTokenDatafast: 'OGE4Mjk0MTg1YTY1YmY1ZTAxNWE2YzhjNzI4YzBkOTV8YmZxR3F3UTMyWA==',
  accessTokenDatafastProd: 'OGFjZGE0Y2I4MWFlODMyMjAxODFiYzE2NDZjZTQ5ZDV8and5ZWFyMmZnYw==',
  idComercioFCDev: '1000000505',
  idTerminalFCDev: 'PD100406',
  idSeguridadFCDev: '0103910',
  idProveedorFCDev: '17913101',
  idComercioFC: '4200003753',
  idTerminalFC: 'BP415079',
  idSeguridadFC: '0103910',
  idProveedorFC: '17913101',
  apiPaypal: 'https://api-m.paypal.com',
  clientIdPaypal: 'AZZ-AwqU7w6ac3w0__YYjQl4pP-gtkhgqlOLLmUQbFbmaOfDBSEaoogoupOtncuWgQk13zWrQPUeqiPd', // 'AWloZ32VRRk07p117x2ShhvmejNxDA5A1pjU2zUEq5TRxzh857Hux-dPU-oXI-8-Q5JxnGVbMYlq4WIB',
  secretPaypal: 'ECSxMZu50k0lqap_91LByCFMfG0q3X5DO_3rLeULYM2Bd3tvo8B8PklXIrbp-ocvWIWClY3f7NKNOoY0', // 'EEocSEairrdJT79GV9sIcQ7tFDj3Uvx8q_8aAIUyeZ6jQQsadio1ib4Ou2AlurRPZJWvAwFa7R7_wIVR',
  firebase: {
    apiKey: "AIzaSyBb4nDQ6BBY8FAMn8Pi9p8-O2K-QCKeDfE",
    authDomain: "digital-contable.firebaseapp.com",
    databaseURL: "https://digital-contable.firebaseio.com",
    projectId: "digital-contable",
    storageBucket: "digital-contable.appspot.com",
    messagingSenderId: "621471658986",
    appId: "1:621471658986:web:604ffb699de38784"
  },
  vapidKey: 'BIDPctnXHQDIjcOXxDS6qQcz-QTws7bL8v7UPgFnS1Ky5BZL3jS3-XXfxwRHmAUMOk7pXme7ttOBvVoIfX57PEo',
  recaptcha3SiteKey: '6LeI1VocAAAAAEHdUR_I_p2dDBkes4Hu7c5utbKT',
  pusher: {
    key: 'c8c924c430b04cb4022d',
    cluster: 'us2',
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
