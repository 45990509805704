export const fileType = {
  "folder": "folder-alt",
  "xlsx": "file-type-sheet",
  "xls": "file-type-sheet",
  "doc": "file-type-doc",
  "docx": "file-type-doc",
  "pdf": "file-type-pdf",
  "rar": "file-type-zip",
  "zip": "file-type-zip",
  "txt": "file-type-text",
  "pptx": 'file-type-ppt',
  "mp3": "mp3",
  "jpg": "file-type-media",
  "png": "png",
  "gif": "file-media",
  "mov": "file-type-movie-alt",
  "avi": "file-type-movie-alt"
}
