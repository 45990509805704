import { createAction, props } from '@ngrx/store';
//ACCESO
export const validateAccess = createAction(
  '[LOGIN] [VALIDATEACCESS] VALIDAR ACCESO',
  props<{ user: string; password: string }>()
);
export const get = createAction(
  '[LOGIN] [GET] OBTENER LOGIN'
);
export const set = createAction(
  '[LOGIN] [SET] REGISTRAR LOGIN',
  props<{ respuesta: any }>()
);
export const setStore = createAction(
  '[LOGIN] [SET] REGISTRAR LOGIN EN LOCAL STORE',
  props<{ respuesta: any }>()
);
//REGISTRAR ERROR
export const setError = createAction(
  '[LOGIN] [ERROR] REGISTRAR ERROR',
  props<{ error: any }>()
);
//LIMPIAR TODO EL STORE DE LOGIN
export const clean = createAction(
  '[LOGIN] [CLEAN] LIMPIAR LOGIN - STORE'
);
export const setCleanOk = createAction(
  '[LOGIN] [SET] LIMPIEZA CORRECTO'
);
