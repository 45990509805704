import { Directive, ElementRef, HostListener, Output, EventEmitter } from '@angular/core';

@Directive({
  selector: '[appTrim]'
})
export class TrimDirective {

  @Output() ngModelChange = new EventEmitter();

  constructor(
    private elementRef: ElementRef
  ) { }

  @HostListener('blur', ['$event']) setInputFocusOut(event) {
    const resEventValue = event.target.value;
    this.elementRef.nativeElement.value = resEventValue.trim();
    this.ngModelChange.emit(this.elementRef.nativeElement.value);
  }

}
