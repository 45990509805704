import { Injectable } from '@angular/core';
import { LocalStoreService } from '../../service/local-store/local-store.service';
import { RespuestaApiVM } from '../../data-models/view-model/RespuestaApiVM';
import { fileType } from '../../ultil/fileType';

@Injectable({
  providedIn: 'root'
})
export class EffectsBase {

  constructor(
    private localStoreService: LocalStoreService
  ){}
  public TransformarRespuestaWebApi (respuestaHttp: any) {
    const respuestaApiVM = new RespuestaApiVM();
    respuestaApiVM.Code = 404;
    respuestaApiVM.Data = null;
    respuestaApiVM.Messages = null;
    respuestaApiVM.Status = false;
    try{
      if (respuestaHttp){
        if (respuestaHttp.Code) {
          respuestaApiVM.Code = respuestaHttp.Code;
        }
        if (respuestaHttp.Data) {
          respuestaApiVM.Data = respuestaHttp.Data;
        }
        if (respuestaHttp.Message) {
          respuestaApiVM.Messages = respuestaHttp.Message;
        }
        if (respuestaHttp.Status) {
          respuestaApiVM.Status = respuestaHttp.Status;
        }
      }
    }catch (Exception){
      respuestaApiVM.Messages = [Exception.message]
    }
    return respuestaApiVM;
  }

  public GetEmpresaList(respuesta: RespuestaApiVM){
    let lista = [];
    try {
      if(respuesta != undefined && respuesta != null){
        if(respuesta.Data != null){
          if(respuesta.Data.empresas != null){
            lista = [...respuesta.Data.empresas];
          }
        }
      }
    } catch (error) {
      console.error('Error EffectsBase -> GetEmpresaList()', error);
    }
    return lista;
  }

  public GetEmpresaSeleccionada(respuesta: RespuestaApiVM){
    let item = {};
    try {
      if(respuesta != undefined && respuesta != null){
        if(respuesta.Data != null){
          if(respuesta.Data.empresas != null){
            const lista = [...respuesta.Data.empresas];
            lista.forEach(itemlista => {
              if(itemlista.selecionado){
                item = {...itemlista};
              }
            });
          }
        }
      }
    } catch (error) {
      console.error('Error EffectsBase -> GetEmpresaList()', error);
    }
    return item;
  }

  public GetModuloList(respuesta: RespuestaApiVM){
    let lista = [];
    try {
      if(respuesta != undefined && respuesta != null){
        if(respuesta.Data != null){
          if(respuesta.Data.modulos != null){
            lista = [...respuesta.Data.modulos];
          }
        }
      }
    } catch (error) {
      console.error('Error EffectsBase -> GetModuloList()', error);
    }
    return lista;
  }

  public GetUsuario(respuesta: RespuestaApiVM){
    let usuario = undefined;
    try {
      if(respuesta != undefined && respuesta != null){
        if(respuesta.Data != null){
          let data: any = respuesta.Data;
          delete data.empresas;
          delete data.modulos;
          usuario = {...data};
        }
      }
    } catch (error) {
      console.error('Error EffectsBase -> GetModuloList()', error);
    }
    return usuario;
  }

  public GetSubmenu(respuesta: RespuestaApiVM){
    let lista = [];
    try {
      if(respuesta != undefined && respuesta != null){
        if(respuesta.Data != null){
          lista = [...respuesta.Data];
        }
      }
    } catch (error) {
      console.error('Error EffectsBase -> GetSubmenu', error);
    }
    return lista;
  }

  getImageByTypeFile(type){
    let image = "file-doc";
    const typeSearch = fileType[type] ? fileType[type] : null;
    if(typeSearch != null){
      image = typeSearch;
    }
    return image;
  }

  validSize(size): string{
    let style = '';
    if(size > 31457280){
      style = 'file-invalido';
    }
    return style;
  }

  public GetFileList(respuesta: RespuestaApiVM){
    let lista = [];
    try {
      if(respuesta != undefined && respuesta != null){
        if(respuesta.Data != null){
          const repositorios = [...respuesta.Data];
          repositorios.forEach(repositorio => {
            if(repositorio.tipo === 'archivo'){
              lista.push(repositorio);
            }
          });
        }
      }
    } catch (error) {
      console.error('Error EffectsBase -> GetFileList()', error);
    }
    return lista;
  }

  public GetFolderList(respuesta: RespuestaApiVM){
    let lista = [];
    try {
      if(respuesta != undefined && respuesta != null){
        if(respuesta.Data != null){
          const repositorios = [...respuesta.Data];
          repositorios.forEach(repositorio => {
            if(repositorio.tipo === 'carpeta'){
              lista.push(repositorio);
            }
          });
        }
      }
    } catch (error) {
      console.error('Error EffectsBase -> GetFolderList()', error);
    }
    return lista;
  }
}
