import { EffectsBase } from './EffectsBase';
import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { catchError, exhaustMap, map } from 'rxjs/operators';
import { of } from 'rxjs';
import * as dataGridAction from '../actions/dataGrid.action';
import { CrudBasicService } from '../../service/crud-basic/crudBasic.service';
import { ToastrService } from 'ngx-toastr';
import { Store } from '@ngrx/store';

@Injectable({
  providedIn: 'root'
})
export class CrudBasicEffects {
  constructor(
    private store: Store,
    private effectsBase: EffectsBase,
    private actions$: Actions,
    private crudBasicService: CrudBasicService,
    private toastr: ToastrService
  ) {}

  GetDatos$ = createEffect(() =>
    this.actions$.pipe(
      ofType(dataGridAction.getDatos),
      exhaustMap(action =>
        this.crudBasicService.getDatos(action.modulo, action.filtros).pipe(
          map((resApi: any) => {
            const res = this.effectsBase.TransformarRespuestaWebApi(resApi);
            this.store.dispatch(dataGridAction.cleanDatos());
            this.store.dispatch(dataGridAction.setRespuestaWebApi({
              respuestaWebApi: res,
              accion: 'GET'
            }));
            return dataGridAction.setDatos( { datos: res.Data });
          }),
          catchError(errorRes => {
            return of(dataGridAction.setError({ error: errorRes }));
          })
        )
    ))
  );
  DeleteDatos$ = createEffect(() =>
    this.actions$.pipe(
      ofType(dataGridAction.deleteDatos),
      exhaustMap(action =>
        this.crudBasicService.deleteDatos(action.modulo, action.filtrosDelete).pipe(
          map((resApi: any) => {
            const res = this.effectsBase.TransformarRespuestaWebApi(resApi);
            this.store.dispatch(dataGridAction.clear());
            this.store.dispatch(dataGridAction.setRespuestaWebApi({
              respuestaWebApi: res,
              accion: 'DELETE'
            }));
            return dataGridAction.getDatos({
              modulo: action.modulo
            });
          }),
          catchError(errorRes => of(dataGridAction.setError({ error: errorRes })))
        )
    ))
  );
}
