import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';

@Injectable({
    providedIn: 'root'
})
export class TxtsuperciasRedirectGuard implements CanActivate {
    canActivate(): boolean {
        window.location.href = 'http://facilcontabilidad.org/txtsupercias';
        return false;
    }
}
