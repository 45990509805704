import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule, Routes } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { HttpClientInMemoryWebApiModule } from 'angular-in-memory-web-api';
import { FakeDbService } from '@fake-db/fake-db.service';

import 'hammerjs';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ToastrModule } from 'ngx-toastr';
import { TranslateModule } from '@ngx-translate/core';
import { ContextMenuModule } from '@ctrl/ngx-rightclick';

import { CoreModule } from '@core/core.module';
import { CoreCommonModule } from '@core/common.module';
import { CoreSidebarModule, CoreThemeCustomizerModule } from '@core/components';
import { CardSnippetModule } from '@core/components/card-snippet/card-snippet.module';

import { coreConfig } from 'app/app-config';
import { AuthGuard } from 'app/auth/helpers/auth.guards';
import { fakeBackendProvider } from 'app/auth/helpers'; // used to create fake backend
import { JwtInterceptor, ErrorInterceptor } from 'app/auth/helpers';
import { AppComponent } from 'app/app.component';
import { LayoutModule } from 'app/layout/layout.module';
import { ContentHeaderModule } from 'app/layout/components/content-header/content-header.module';

import { ContextMenuComponent } from 'app/main/extensions/context-menu/context-menu.component';
import { AnimatedCustomContextMenuComponent } from './main/extensions/context-menu/custom-context-menu/animated-custom-context-menu/animated-custom-context-menu.component';
import { BasicCustomContextMenuComponent } from './main/extensions/context-menu/custom-context-menu/basic-custom-context-menu/basic-custom-context-menu.component';
import { SubMenuCustomContextMenuComponent } from './main/extensions/context-menu/custom-context-menu/sub-menu-custom-context-menu/sub-menu-custom-context-menu.component';
import { AdminRedirectGuard } from "./auth/helpers/adminredirect.guard";
import { CastorRedirectGuard } from "./auth/helpers/castorredirect.guard";
import {AcademyRedirectGuard} from "./auth/helpers/academyredirect.guard";
import {IflucRedirectGuard} from "./auth/helpers/iflucredirect.guard";
import {TxtsuperciasRedirectGuard} from "./auth/helpers/txtsuperciasredirect.guard";
import {DigitalcontableRedirectGuard} from "./auth/helpers/digitalcontable.guard";
import {FacturafullRedirectGuard} from "./auth/helpers/facturafullredirect.guard";

import { TrimDirective } from "./directives/trim.directive";
import { UppercaseDirective } from "./directives/uppercase.directive";
import { CompleteCerosIzquierdaDirective } from "./directives/complete-ceros-izquierda.directive";
import { CompleteDecimalsDirective } from "./directives/complete-decimals.directive";
import { MaskDecimalDirective } from "./directives/mask-decimal.directive";
import { MaskDecimalNegativoDirective } from "./directives/mask-decimal-negativo.directive";
import { MaskDecimalPositivoDirective } from "./directives/mask-decimal-positivo.directive";
import { MaskIntegerDirective } from "./directives/mask-integer.directive";
import { MaskIntegerNegativoDirective } from "./directives/mask-integer-negativo.directive";
import { MaskIntegerPositivoDirective } from "./directives/mask-integer-positivo.directive";
import { SelectInFocusDirective } from "./directives/select-in-focus.directive";

import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { EffectsModule } from '@ngrx/effects';

import { LoginEffects } from './_global/ngrx/effects/login.effects';
import { CrudBasicEffects } from './_global/ngrx/effects/dataGrid.effects';
import { ItemFormEffects } from './_global/ngrx/effects/itemForm.effects';
import { FileEffects } from './_global/ngrx/effects/file.effects';

import { AngularFireModule } from '@angular/fire/compat';
import { ScreenTrackingService, UserTrackingService } from '@angular/fire/analytics';
import { AngularFireDatabaseModule } from '@angular/fire/compat/database';

import { loginReducer } from "./_global/ngrx/reducers/login.reducer";
import { environment } from '../environments/environment';

const appRoutes: Routes = [
  {
    path: 'academyfc',
    loadChildren: () => import('./main/academyfc/academyfc.module').then(m => m.AcademyfcModule)
  },
  {
    path: 'adminfc',
    loadChildren: () => import('./main/adminfc/adminfc.module').then(m => m.AdminfcModule)
  },
  {
    path: 'dashboardadmin',
    loadChildren: () => import('./main/dashboard/dashboard.module').then(m => m.DashboardModule)
  },
  {
    path: 'apps',
    loadChildren: () => import('./main/apps/apps.module').then(m => m.AppsModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'pages',
    loadChildren: () => import('./main/pages/pages.module').then(m => m.PagesModule)
  },
  {
    path: 'ui',
    loadChildren: () => import('./main/ui/ui.module').then(m => m.UIModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'components',
    loadChildren: () => import('./main/components/components.module').then(m => m.ComponentsModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'extensions',
    loadChildren: () => import('./main/extensions/extensions.module').then(m => m.ExtensionsModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'forms',
    loadChildren: () => import('./main/forms/forms.module').then(m => m.FormsModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'tables',
    loadChildren: () => import('./main/tables/tables.module').then(m => m.TablesModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'charts-and-maps',
    loadChildren: () => import('./main/charts-and-maps/charts-and-maps.module').then(m => m.ChartsAndMapsModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'castor',
    loadChildren: () => import('./main/pages/pages.module').then(m => m.PagesModule),
    canActivate: [CastorRedirectGuard]
  },
  {
    path: 'ifluc',
    loadChildren: () => import('./main/pages/pages.module').then(m => m.PagesModule),
    canActivate: [IflucRedirectGuard]
  },
  {
    path: 'txtsupercias',
    loadChildren: () => import('./main/pages/pages.module').then(m => m.PagesModule),
    canActivate: [TxtsuperciasRedirectGuard]
  },
  {
    path: 'digitalcontable',
    loadChildren: () => import('./main/pages/pages.module').then(m => m.PagesModule),
    canActivate: [DigitalcontableRedirectGuard]
  },
  {
    path: 'facturafull',
    loadChildren: () => import('./main/pages/pages.module').then(m => m.PagesModule),
    canActivate: [FacturafullRedirectGuard]
  },
  {
    path: '',
    redirectTo: '/pages/home',
    pathMatch: 'full',
  },
  {
    path: '**',
    redirectTo: '/pages/miscellaneous/error' //Error 404 - Page not found
  }
];

@NgModule({
  declarations: [
    AppComponent,
    ContextMenuComponent,
    BasicCustomContextMenuComponent,
    AnimatedCustomContextMenuComponent,
    SubMenuCustomContextMenuComponent,
    TrimDirective,
    UppercaseDirective,
    CompleteCerosIzquierdaDirective,
    CompleteDecimalsDirective,
    MaskDecimalDirective,
    MaskDecimalNegativoDirective,
    MaskDecimalPositivoDirective,
    MaskIntegerDirective,
    MaskIntegerPositivoDirective,
    MaskIntegerNegativoDirective,
    SelectInFocusDirective,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    HttpClientInMemoryWebApiModule.forRoot(FakeDbService, {
      delay: 0,
      passThruUnknownUrl: true
    }),
    RouterModule.forRoot(appRoutes, {
      scrollPositionRestoration: 'enabled', // Add options right here
      relativeLinkResolution: 'legacy'
    }),
    NgbModule,
    ToastrModule.forRoot({
      timeOut: 10000,
      positionClass: 'toast-top-right',
      preventDuplicates: true,
    }),
    TranslateModule.forRoot(),
    ContextMenuModule,
    CoreModule.forRoot(coreConfig),
    CoreCommonModule,
    CoreSidebarModule,
    CoreThemeCustomizerModule,
    CardSnippetModule,
    LayoutModule,
    ContentHeaderModule,
    StoreModule.forRoot({
      login: loginReducer
    }),
    StoreDevtoolsModule.instrument({
      maxAge: 60,
      logOnly: environment.production,
    }),
    EffectsModule.forRoot([
      LoginEffects,
      CrudBasicEffects,
      ItemFormEffects,
      FileEffects
    ]),
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireDatabaseModule,
  ],

  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },

    // ! IMPORTANT: Provider used to create fake backend, comment while using real API
    // fakeBackendProvider,
    ScreenTrackingService,
    UserTrackingService,
  ],
  entryComponents: [BasicCustomContextMenuComponent, AnimatedCustomContextMenuComponent],
  exports: [
    TrimDirective,
    UppercaseDirective,
    CompleteCerosIzquierdaDirective,
    CompleteDecimalsDirective,
    MaskDecimalDirective,
    MaskDecimalNegativoDirective,
    MaskDecimalPositivoDirective,
    MaskIntegerDirective,
    MaskIntegerPositivoDirective,
    MaskIntegerNegativoDirective,
    SelectInFocusDirective
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
