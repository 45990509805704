import { CoreMenu } from '@core/types';

//? DOC: http://localhost:7777/demo/vuexy-angular-admin-dashboard-template/documentation/guide/development/navigation-menus.html#interface

export const menu: CoreMenu[] = [
  {
    id: 'home',
    title: 'Home',
    translate: 'MENU.DASHBOARD.HOME',
    type: 'item',
    icon: 'home',
    role: ['Admin', 'Client'],
    url: '/pages/home',
  },
  {
    id: 'grupos',
    type: 'item',
    title: 'Grupos',
    translate: 'MENU.APPS.SECTION',
    icon: 'users',
    role: ['Client'],
    url: '/pages/groups'
  },
    /*
  {
    id: 'software',
    type: 'item',
    title: 'Software',
    translate: 'MENU.UI.SOFTWARE',
    icon: 'monitor',
    url: '/pages/software'
  },
     */
  {
    id: 'contacto',
    title: 'Contacto',
    translate: 'MENU.DASHBOARD.CONTACTO',
    type: 'item',
    icon: 'smile',
    role: ['Client'],
    url: '/pages/contacto',
  },
  {
    id: 'home',
    title: 'Mis cursos',
    translate: 'MENU.DASHBOARD.USERHOME',
    type: 'item',
    role: ['Client'],
    url: '/academyfc/userhome',
  },
  {
    id: 'academy',
    title: 'Academy',
    translate: 'MENU.APPS.ACADEMY',
    type: 'collapsible',
    role: ['Admin'],
    badge: {
      title: (localStorage.getItem('sumanotificaciones')) ? JSON.parse(localStorage.getItem('sumanotificaciones')) : 0,
      translate: 'MENU.DASHBOARD.BADGE',
      classes: 'badge-light-warning badge-pill'
    },
    children: [
      {
        id: 'usersacademy',
        title: 'Usuarios',
        translate: 'MENU.PAGES.USUARIOSACADEMY',
        type: 'item',
        icon: 'circle',
        url: 'adminfc/academia/usuarios/usuarios-list',
        badge: {
          title: (localStorage.getItem('newusers')) ? JSON.parse(localStorage.getItem('newusers')) : 0,
          translate: 'MENU.DASHBOARD.BADGE',
          classes: 'badge-light-warning badge-pill'
        },
      },
      {
        id: 'productosacademy',
        title: 'Cursos',
        translate: 'MENU.PAGES.PRODUCTOSACADEMY',
        type: 'item',
        icon: 'circle',
        url: 'adminfc/academia/products/product-list-academia'
      },
      {
        id: 'promosacademy',
        title: 'Promos',
        translate: 'MENU.PAGES.PROMOSACADEMY',
        type: 'item',
        icon: 'circle',
        url: 'adminfc/academia/packets/packet-list-academia'
      },
      {
        id: 'categoriasacademy',
        title: 'Categorías',
        translate: 'MENU.PAGES.CATEGORIASACADEMY',
        type: 'item',
        icon: 'circle',
        url: 'adminfc/academia/categories/categories'
      },
      {
        id: 'subcategoriasacademy',
        title: 'SubCategorías',
        translate: 'MENU.PAGES.SUBCATEGORIASACADEMY',
        type: 'item',
        icon: 'circle',
        url: 'adminfc/academia/subcategories/subcategories'
      },
      {
        id: 'comentariosacademy',
        title: 'Comentarios',
        translate: 'MENU.PAGES.COMENTARIOSACADEMY',
        type: 'item',
        icon: 'circle',
        url: 'adminfc/academia/comments/comments',
        badge: {
          title: (localStorage.getItem('comentariospendientes')) ? JSON.parse(localStorage.getItem('comentariospendientes')) : 0,
          translate: 'MENU.DASHBOARD.BADGE',
          classes: 'badge-light-danger badge-pill'
        },
      }
    ]
  },
  {
    id: 'cupones',
    title: 'Cupones',
    translate: 'MENU.PAGES.COUPONS',
    type: 'item',
    role: ['Admin'],
    url: 'adminfc/cupones/cupones-list'
  },
  {
    id: 'ventas',
    title: 'Ventas',
    translate: 'MENU.APPS.INVOICE.COLLAPSIBLE',
    type: 'item',
    role: ['Admin'],
    url: 'adminfc/ventas/ventas-list'
  },
  {
    id: 'getresponse',
    title: 'GetResponse',
    translate: 'MENU.APPS.GETRESPONSE',
    type: 'collapsible',
    role: ['Admin'],
    children: [
      {
        id: 'campaigns',
        title: 'Listas',
        translate: 'MENU.APPS.CAMPAIGNS',
        type: 'item',
        icon: 'circle',
        url: 'adminfc/getresponse/campaigns-list'
      },
      {
        id: 'contactos',
        title: 'Contactos',
        translate: 'MENU.APPS.CONTACTOS',
        type: 'item',
        icon: 'circle',
        url: 'adminfc/getresponse/contacts-list'
      },
      {
        id: 'configuraciones',
        title: 'Configuraciones',
        translate: 'MENU.APPS.CONFIGURACIONES',
        type: 'item',
        icon: 'circle',
        url: 'adminfc/getresponse/configuraciones'
      }
    ]
  },
  {
    id: 'datafast',
    title: 'Datafast',
    translate: 'MENU.APPS.DATAFAST',
    type: 'collapsible',
    role: ['Admin'],
    children: [
      {
        id: 'transactions',
        title: 'Cobros',
        translate: 'MENU.APPS.TRANSACCIONES',
        type: 'item',
        icon: 'circle',
        url: 'adminfc/datafast/transacciones-list'
      },
      {
        id: 'recurrentes',
        title: 'Recurrentes',
        translate: 'MENU.APPS.RECURRENTES',
        type: 'item',
        icon: 'circle',
        url: 'adminfc/datafast/recurrentes-list'
      },
      {
        id: 'cobrosrecurrentes',
        title: 'Cob.Rec.',
        translate: 'MENU.APPS.COBREC',
        type: 'item',
        icon: 'circle',
        url: 'adminfc/datafast/cobrosrecurrentes-list'
      },
      {
        id: 'logs',
        title: 'Rechazos',
        translate: 'MENU.APPS.LOGS',
        type: 'item',
        icon: 'circle',
        url: 'adminfc/datafast/logs-list'
      }
    ]
  },
  {
    id: 'administration',
    title: 'Usuarios Apps & Academy',
    translate: 'MENU.PAGES.USUARIOS',
    type: 'item',
    url: 'adminfc/users/users-list',
    role: ['Admin'],
    children: [
      /*
      {
        id: 'software',
        title: 'Software',
        translate: 'MENU.PAGES.AUTH.LOGIN2',
        type: 'item',
        icon: 'circle',
        url: 'pages/productos/productos-list'
      },
      {
        id: 'packsoftware',
        title: 'Packs Software',
        translate: 'MENU.PAGES.PACKSOFTWARE',
        type: 'item',
        icon: 'circle',
        url: 'pages/productos/packets-list-productos'
      },
      {
        id: 'users',
        title: 'Usuarios',
        translate: 'MENU.PAGES.USUARIOS',
        type: 'item',
        icon: 'circle',
        url: 'pages/users/users-list'
      },
      */
    ]
  },
];
