export const locale = {
    lang: 'es',
    data: {
        MENU: {
            DASHBOARD: {
                COLLAPSIBLE: 'Dashboards',
                BADGE: '2',
                ANALYTICS: 'Analytics',
                ECOMMERCE: 'eCommerce',
                CONTACTO: 'Contactanos',
                CURSOS: 'Cursos',
                MISCURSOS: 'Mis Cursos',
                PROMOCIONES: 'Promociones',
                HOME: 'Home',
                USERHOME: 'Mis Cursos',
            },
            APPS: {
                SECTION: 'Grupos',
                GENERAL: 'Admin General',
                APLICACIONES: 'Admin Apps',
                IFLUC: 'Admin Ifluc',
                PRODUCTOSIFLUC: 'Productos Ifluc',
                ACADEMY: 'Academy',
                GETRESPONSE: 'Get Response',
                DATAFAST: 'Datafast',
                CAMPAIGNS: 'Listas',
                TRANSACCIONES: 'Cobros',
                CONTACTOS: 'Contactos',
                LOGS: 'Rechazos',
                RECURRENTES: 'Recurrentes',
                COBREC: 'Cob.Recurrentes',
                CONFIGURACIONES: 'Configuraciones',
                CUPONES: 'Admin Cupones',
                EMAIL: 'Email',
                WHATSAPP: 'Whatsapp',
                FACEBOOK: 'Facebook',
                CHAT: 'Chat',
                TODO: 'Todo',
                CALENDAR: 'Calendar',
                INVOICE: {
                    COLLAPSIBLE: 'Invoice',
                    LIST: 'List',
                    PREVIEW: 'Preview',
                    EDIT: 'Edit',
                    ADD: 'Add'
                },
                ECOMMERCE: {
                    COLLAPSIBLE: 'eCommerce',
                    SHOP: 'Shop',
                    DETAIL: 'Details',
                    WISHLIST: 'Wish List',
                    CHECKOUT: 'Checkout'
                },
                USER: {
                    COLLAPSIBLE: 'User',
                    LIST: 'List',
                    VIEW: 'View',
                    EDIT: 'Edit'
                }
            },
            PAGES: {
                SECTION: 'Pages',
                AUTH: {
                    COLLAPSIBLE: 'Productos',
                    LOGIN1: 'Categorias',
                    LOGIN2: 'Software',
                    REGISTER1: 'Register V1',
                    REGISTER2: 'Register V2',
                    FORGOTPASSWORD1: 'Forgot Password V1',
                    FORGOTPASSWORD2: 'Forgot Password V2',
                    RESETPASSWORD1: 'Reset Password V1',
                    RESETPASSWORD2: 'Reset Password V2'
                },
                USUARIOS: 'Usuarios Apps & Academy',
                USUARIOSIFLUC: 'Usuarios IFluc',
                ACADEMY: 'Academy',
                USUARIOSACADEMY: 'Usuarios',
                PRODUCTOSACADEMY: 'Cursos',
                PROMOSACADEMY: 'Promos',
                CATEGORIASACADEMY: 'Categorias',
                SUBCATEGORIASACADEMY: 'SubCategorias',
                COMENTARIOSACADEMY: 'Comentarios',
                COUPONS: 'Cupones',
                ACCOUNTSETTINGS: 'Account Settings',
                PROFILE: 'Profile',
                FAQ: 'FAQ',
                KB: 'Knowledge Base',
                PRICING: 'Pricing',
                BLOG: {
                    COLLAPSIBLE: 'Blog',
                    LIST: 'List',
                    DETAILS: 'Details',
                    EDIT: 'Edit'
                },
                MAIL: {
                    COLLAPSIBLE: 'Mail Template',
                    WELCOME: 'Welcome',
                    RESET: 'Reset Password',
                    VERIFY: 'Verify',
                    DEACTIVATE: 'Deactivate',
                    INVOICE: 'Invoice',
                    PROMOTIONAL: 'Promotional'
                },
                MISC: {
                    COLLAPSIBLE: 'Miscellaneous',
                    COMINGSOON: 'Coming Soon',
                    NOTAUTH: 'Not Authorized',
                    MAINTENANCE: 'Maintenance',
                    ERROR: 'Error'
                }
            },
            UI: {
                SECTION: 'User Interface',
                SOFTWARE: 'Software',
                DIGITAL: 'Digital Contable',
                IFLUC: 'Ifluc',
                FACTURA: 'Factura Full',
                CASTOR: 'Castor X',
                TYPOGRAPHY: 'Typography',
                COLORS: 'Colors',
                FEATHER: 'Feather',
                CARDS: {
                    COLLAPSIBLE: 'Cards',
                    BADGE: 'New',
                    BASIC: 'Basic',
                    ADVANCE: 'Advance',
                    STATISTICS: 'Statistics',
                    ANALYTICS: 'Analytics',
                    ACTIONS: 'Actions'
                },
                COMPONENTS: {
                    COLLAPSIBLE: 'Components',
                    ALERTS: 'Alerts',
                    AVATAR: 'Avatar',
                    BADGES: 'Badges',
                    BREADCRUMBS: 'Breadcrumbs',
                    BUTTONS: 'Buttons',
                    CAROUSEL: 'Carousel',
                    COLLAPSE: 'Collapse',
                    DIVIDER: 'Divider',
                    DROPDOWNS: 'Dropdowns',
                    GROUP: 'List Group',
                    OBJECTS: 'Media Objects',
                    MODALS: 'Modals',
                    COMPONENT: 'Navs',
                    PAGINATION: 'Pagination',
                    PBADGES: 'Pill Badges',
                    PILLS: 'Pills',
                    POPOVERS: 'Popovers',
                    PROGRESS: 'Progress',
                    RATINGS: 'Ratings',
                    SPINNER: 'Spinner',
                    TABS: 'Tabs',
                    TIMELINE: 'Timeline',
                    TOASTS: 'Toasts',
                    TOOLTIPS: 'Tooltips'
                },
                EX: {
                    COLLAPSIBLE: 'Extension',
                    SWEET_ALERTS: 'Sweet Alerts',
                    BLOCKUI: 'BlockUI',
                    TOASTER: 'Toastr',
                    SLIDER: 'Slider',
                    DRAGDROP: 'Drag & Drop',
                    TOUR: 'Tour',
                    CLIPBOARD: 'Clipboard',
                    MEDIAPLAYER: 'Media Player',
                    CONTEXTMENU: 'Context Menu',
                    SWIPER: 'Swiper',
                    TREEVIEW: 'Tree View',
                    I18N: 'I18n'
                },
                LAYOUTS: {
                    COLLAPSIBLE: 'Page Layouts',
                    COLLAPSED_MENU: 'Collapsed Menu',
                    BOXED_LAYOUT: 'Boxed Layout',
                    WITHOUT_MENU: 'Without Menu',
                    LAYOUT_EMPTY: 'Layout Empty',
                    LAYOUT_BLANK: 'Layout Blank'
                }
            },
            FT: {
                SECTION: 'Forms & Tables',
                ELEMENT: {
                    COLLAPSIBLE: 'Form Elements',
                    INPUT: 'Input',
                    INPUTGROUPS: 'Input Groups',
                    INPUTMASK: 'Input Mask',
                    TEXTAREA: 'Textarea',
                    CHECKBOX: 'Checkbox',
                    RADIO: 'Radio',
                    SWITCH: 'Switch',
                    SELECT: 'Select',
                    NUMBERINPUT: 'Number Input',
                    FILEUPLOADER: 'File Uploader',
                    QUILLEDITOR: 'Quill Editor',
                    FLATPICKER: 'Flatpicker',
                    DATETIMEPICKER: 'Date & Time Picker'
                },
                LAYOUTS: 'Form Layouts',
                WIZARD: 'Form Wizard',
                VALIDATION: 'Form Validations',
                REPEATER: 'Form Repeater',
                TABLE: 'Table',
                DATATABLES: 'DataTables'
            },
            CM: {
                SECTION: 'Charts & Maps',
                CHARTS: {
                    COLLAPSIBLE: 'Charts',
                    BADGE: '2',
                    APEX: 'Apex',
                    CHARTJS: 'ChartJS'
                },
                MAPS: 'Google Maps'
            },
            ACADEMIA: {
                USUARIOS: {
                    COLLAPSIBLE: 'Usuarios',
                    LIST: 'Usuarios',
                    VIEW: 'View',
                    EDIT: 'Edit'
                },
            },
            OTHERS: {
                SECTION: 'Others',
                LEVELS: {
                    COLLAPSIBLE: 'Menu Levels',
                    SECOND: 'Second Level',
                    SECOND1: {
                        COLLAPSIBLE: 'Second Level',
                        THIRD: 'Third Level',
                        THIRD1: 'Third Level'
                    }
                },
                DISABLED: 'Disabled Menu',
                DOCUMENTATION: 'Documentation',
                SUPPORT: 'Raise Support'
            }
        }
    }
};
