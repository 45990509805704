import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from 'environments/environment';
import { User, Role } from 'app/auth/models';
import { ToastrService } from 'ngx-toastr';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
  //public
  public currentUser: Observable<User>;

  //private
  private currentUserSubject: BehaviorSubject<User>;

  configuraciones: any;
  onConfiguracionestablessChanged: BehaviorSubject<any>;

  /**
   *
   * @param {HttpClient} _http
   * @param {ToastrService} _toastrService
   */
  constructor(
      private _http: HttpClient,
      private _toastrService: ToastrService
  ) {
    this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')));
    this.currentUser = this.currentUserSubject.asObservable();
    this.onConfiguracionestablessChanged = new BehaviorSubject({});
  }

  // getter: currentUserValue
  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }

  /**
   *  Confirms if user is admin
   */
  get isAdmin() {
    console.log("Role.Admin: ", Role.Admin)
    console.log(this.currentUserSubject.value.role)
    return this.currentUser && this.currentUserSubject.value.roleId === Role.Admin;
  }

  /**
   *  Confirms if user is client
   */
  get isClient() {
    return this.currentUser && this.currentUserSubject.value.roleId === Role.Client;
  }


  /**
   * User login
   *
   * @param email
   * @param password
   * @returns user
   */
  login(email: string, password: string) {
    // console.log('email', email);
    // console.log('password', password);
    // console.log(`${environment.apiUrl}/token`)
    return this._http
        .post<any>(`${environment.apiUrl}/token`, { email, password })
        .pipe(map(data => {
            console.log(JSON.stringify(data));
            // login successful if there's a jwt token in the response
            if (data && data.token) {
              data.user.fullName = data.user.detalles[0].name;
              data.user.company = 'Facil Contabilidad';
              data.user.country = 'Ecuador';
              data.user.contact = data.user.detalles[0].celular;
              data.user.currentPlan = data.user.roleId;
              data.user.status = 'active';
              data.user.avatar = './assets/images/avatars/avatar.png';
              data.user.token = data.token;
              // store user details and jwt token in local storage to keep user logged in between page refreshes
              localStorage.setItem('currentUser', JSON.stringify(data.user));

              // Display welcome toast!
              setTimeout(() => {
                this._toastrService.success(
                    'Te has logueado correctamente como usuario',
                    '👋 Bienvenidos, ' + data.user.detalles[0].name + '!',
                    { toastClass: 'toast ngx-toastr', closeButton: true }
                );
              }, 2500);

              // notify
              this.currentUserSubject.next(data.user);
            } else if (data && !data.auth) {
              data.message = 'No  estas autorizado para ingresar a la plataforma o tu membresía aún no está activa, consulta con el administrador de la cuenta'
              setTimeout(() => {
                this._toastrService.error(
                    'No  estas autorizado para ingresar a la plataforma o tu membresía aún no está activa, consulta con el administrador de la cuenta',
                    'Error al loguearte!',
                    { toastClass: 'toast ngx-toastr', closeButton: true }
                );
              }, 500)
            } else {
              data.message = 'No tenemos una cuenta registrada con los datos que ingresaste o tu cuenta aún no esta activa, por favor crea una para poder disfrutar de los mejores cursos del mercado para profesionales o consulta con soporte técnico para activar tu cuenta'
              setTimeout(() => {
                this._toastrService.error(
                    'No tenemos una cuenta registrada con los datos que ingresaste, por favor crea una para poder disfrutar de los mejores cursos del mercado para profesionales',
                    'Error al loguearte!',
                    { toastClass: 'toast ngx-toastr', closeButton: true }
                );
              }, 500)
            }
            return data;
          })
        );
  }


  /**
   * User login
   *
   * @param usuario
   * @returns user
   */
  register(usuario: any) {
    return this._http.post<any>(`${environment.apiUrl}/usersfc/academy`, { usuario })
        .pipe(map(data => {
              // console.log(data);
              // login successful if there's a jwt token in the response
              if (data && data.token) {
                  data.response.fullName = data.response.name;
                  data.response.company = 'Facil Contabilidad';
                  data.response.country = 'Ecuador';
                  data.response.contact = data.response.celular;
                  data.response.currentPlan = data.response.role;
                  data.response.status = 'active';
                  data.response.avatar = './assets/images/avatars/avatar.png';
                  data.response.token = data.token;
                // store user details and jwt token in local storage to keep user logged in between page refreshes
                // localStorage.setItem('currentUser', JSON.stringify(data.response));

                // Display welcome toast!
                setTimeout(() => {
                  this._toastrService.success(
                      'Te has registrado y logueado correctamente como usuario',
                      '👋 Bienvenidos, ' + data.response.name + '!',
                      { toastClass: 'toast ngx-toastr', closeButton: true }
                  );
                }, 1500);

                // notify
                // this.currentUserSubject.next(data.response);
              } else if (data && !data.auth) {
                setTimeout(() => {
                  this._toastrService.error(
                      'No  estas autorizado para ingresar a la plataforma, consulta con el administrador de la cuenta',
                      'Error al loguearte!',
                      { toastClass: 'toast ngx-toastr', closeButton: true }
                  );
                }, 500)
              } else {
                setTimeout(() => {
                  this._toastrService.error(
                      'No tenemos una cuenta registrada con los datos que ingresaste, por favor crea una para poder disfrutar de los mejores cursos del mercado para profesionales',
                      'Error al loguearte!',
                      { toastClass: 'toast ngx-toastr', closeButton: true }
                  );
                }, 500)
              }
              return data;
            }, error => {
              console.log(error)
            })
        );
  }

  resetPass = (email: string): Observable<any> => {
    return this._http.post<any>(`${environment.apiUrl}/usersfc/sendRecoveryAcademy`, {email})
        .pipe(map(response => {
          // console.log(response);
          setTimeout(() => {
            this._toastrService.success(
                'Se ha enviado las instrucciones de recuperación de contraseñas a tu email',
                'Recuperar password!',
                { toastClass: 'toast ngx-toastr', closeButton: true }
            );
          }, 500)
          return response;
        }));
  }

  createNewPass = (values: object): Observable<any> => {
    return this._http.post<any>(`${environment.apiUrl}/usersfc/resetPassword`, values)
        .pipe(map(response => {
          // console.log(response);
          setTimeout(() => {
            this._toastrService.success(
                'Se ha creado tu nuevo password de forma correcta',
                'Resetear nuevo password!',
                { toastClass: 'toast ngx-toastr', closeButton: true }
            );
          }, 500)
          return response;
        }));
  }

  actualizarCurrentUser(id) {
    return this._http.get<any>(`${environment.apiUrl}/users/${id}`)
        .pipe(map(response => {
            // console.log(response);
            return response;
        }));
  }

  /**
   * User logout
   *
   */
  logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('currentUser');
    localStorage.removeItem('location');
    localStorage.removeItem('plyr');
    localStorage.removeItem('leccion');
    localStorage.removeItem('cache-sprite-plyr');
    localStorage.removeItem('curso');
    localStorage.removeItem('comentarios');
    localStorage.removeItem('grupowhatsapp');
    localStorage.removeItem('leccionesvistas');
    // localStorage.removeItem('moduloactivo');
    localStorage.removeItem('ultimaleccion');
    // notify
    this.currentUserSubject.next(null);
  }

  getConfiguraciones() {
    return this._http.get<any>(`${environment.apiUrl}/configuracionesfc`)
      .pipe(map(response => {
          // console.log(response);
          return response;
      }));
  }

  agregarListasDeCorreos = (usuario: object): Observable<any> => {
    return this._http.post<any>(`${environment.apiUrl}/crearcontacto`, { usuario })
        .pipe(map(response => {
            // console.log(response);
            return response;
        }));
  }
}


