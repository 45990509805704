import { createAction, props } from '@ngrx/store';

export const setInciarForm = createAction(
  '[ITEM] [SET] INCIAR FORMULARIO CON ID',
  props<{ formId: any }>()
);

export const clearByFormId = createAction(
  '[ITEM] [CLEAR] LIMPIAR DATOS POR ID',
  props<{ formId: any }>()
);

export const clearAll = createAction(
  '[ITEM] [CLEAR] LIMPIAR TODOS LOS DATOS'
);

export const getDatos = createAction(
  '[ITEM] [GET] OBTENER DATOS PARA EL FORMULARIO',
  props<{ formId: any, modulo: string, filtros: any }>()
);

export const setDatos = createAction(
  '[ITEM] [SET] CARGAR DATOS DEL FORMULARIO',
  props<{ formId: any, datosForm: any }>()
);

export const clearDatos = createAction(
  '[ITEM] [CLEAR] LIMPIAR DATOS DEL FORMULARIO',
  props<{ formId: any }>()
);

export const guardarDatos = createAction(
  '[ITEM] [POST] GUARDAR DATOS',
  props<{ formId: any, modulo: string, datosGuardar: any }>()
);

export const uploadFile = createAction(
  '[ITEM] [POST] SUBIR ARCHIVOS',
  props<{ formId: any, modulo: string, datosGuardar: any }>()
);

export const actulizarDatos = createAction(
  '[ITEM] [PUT] ACTUALIZAR DATOS',
  props<{ formId: any, modulo: string, datosGuardar: any, filtros: string[] }>()
);

export const deleteDatos = createAction(
  '[ITEM] [DELETE] ELIMINAR DATOS',
  props<{ formId: any, modulo: string, filtros: string[] }>()
);

export const getEmpresas = createAction(
  '[ITEM] [GET] OBTENER EMPRESAS',
  props<{ modulo: string, formId: any, filtros: string[]}>()
);

export const setEmpresas = createAction(
  '[ITEM] [SET] CARGAR LISTA EMPRESAS',
  props<{ formId: any, datos: any[] }>()
);

export const getGrupos = createAction(
  '[ITEM] [GET] OBTENER GRUPOS',
  props<{ modulo: string, formId: any, filtros: string[]}>()
);

export const setGrupos = createAction(
  '[ITEM] [SET] CARGAR LISTA GRUPOS',
  props<{ formId: any, datos: any[] }>()
);

export const getEmpresasGruposByUsuario = createAction(
  '[ITEM] [GET] OBTENER EMPRESAS Y GRUPOS POR USUARIO',
  props<{ modulo: string, formId: any, filtros: any}>()
);

export const setEmpresasGruposByUsuario = createAction(
  '[ITEM] [SET] CARGAR EMPRESAS Y GRUPOS POR USUARIO',
  props<{ formId: any, datos: any[] }>()
);

export const getAllModulo = createAction(
  '[ITEM] [GET] OBTENER LISTA DE TODOS LOS MODULOS',
  props<{ formId: any }>()
);

export const setAllModulo = createAction(
  '[ITEM] [SET] REGISTRAR LISTA DE TODOS LOS MODULOS',
  props<{ formId: any, moduloList: any }>()
);

export const cleanAllModulo = createAction(
  '[ITEM] [CLEAN] LIMPIAR LISTA DE TODOS LOS MODULOS',
  props<{ formId: any }>()
);

export const getSubModuloByModulo = createAction(
  '[ITEM] [GET] OBTENER SUBMODULOS POR MODULO',
  props<{ formId: any, id_modulo: any}>()
);

export const setSubModuloByModulo = createAction(
  '[ITEM] [SET] REGISTRAR SUBMODULOS POR MODULO',
  props<{ formId: any, subModuloList: any }>()
);

export const cleanSubModuloByModulo = createAction(
  '[ITEM] [CLEAN] LIMPIAR SUBMODULOS POR MODULO',
  props<{ formId: any }>()
);

export const setError = createAction(
  '[ITEM] [ERROR] CARGAR ERROR',
  props<{ formId: any, error: any }>()
);

export const setRespuesta = createAction(
  '[ITEM] [SET] REGISTRAR RESPUESTA',
  props<{ formId: any, code: number, status: boolean, data: any, messages: any }>()
);

export const clearRespuesta = createAction(
  '[ITEM] [CLEAN] LIMPIAR RESPUESTA',
  props<{ formId: any }>()
);

export const setRefrescarLogin = createAction(
  '[ITEM] [POST] REFRESCAR LOGIN',
  props<{ formId: any, modulo: string, datosGuardar: any }>()
);


