import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[appTextUppercase]'
})
export class UppercaseDirective {

  lastValue: string;

  constructor(
    private elementRef: ElementRef
  ) { }

  @HostListener('input', ['$event']) onInput(event) {
    const resEventValue = event.target.value.toUpperCase();

    if (!this.lastValue || (this.lastValue && event.target.value.length > 0 && this.lastValue !== resEventValue)) {
      this.lastValue = this.elementRef.nativeElement.value = resEventValue;
      const evt = document.createEvent('HTMLEvents');
      evt.initEvent('input', false, true);
      event.target.dispatchEvent(evt);
    }
  }

}
